.vertical-timeline-element{
 margin:1em !important;
}

.vertical-timeline::before{
  background-color:rgb(173, 184, 243);
}

.vertical-timeline-element-icon{
    width:20px !important;
    height:20px !important;
    left:-5px;
    top:50px;
}

.vertical-timeline-element-content{  
  padding:0px;
}

.imagen_icono{
    width:100px !important;
    position: absolute;
    left:-40px;
}

/*.vertical-timeline-element-content:hover:after{
  content: "";
  border-width: 1px;        
  border-color: red;
  border-style: solid;
  position: absolute;
  top:0px;  
  right:-60px;
  width: 60px;
  height:100%;
}*/

/*.noterminado::after{
    content:"";
    position: absolute;
    top:0px;
    left:-5px;
    width: 100%;
    height:100%;
    opacity: .5;
    background-color: white;
}*/



/*.item_vertical > div{
    border-left-width: 4px;        
    border-left-color: red;
    border-left-style: solid;
    margin-left: 2px;
}*/


.item{
  display: flex;
  justify-content: space-between;  
}


.item > .row{
  width:100%;
  margin-left:0px;
}

.item:hover > .item_arrastrar{
  visibility: visible;  
}

/*.item_arrastrar{
  width:150px;  
  background-color:blue;    
  visibility: hidden;
  margin-left: -15px;
  margin-top:15px;
  margin-bottom: 15px;
}*/

.item_arrastrar{
  background-color: #edf0fc;
  /*margin-top: -16px;*/
  margin-bottom: -39px;
  /*margin-right: -16px;
  margin-left:15px;*/
  position: absolute;
  right:-60px;
  height:100%;

  visibility: hidden;
  display:flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  min-width: 60px;
  flex-direction: column;
  user-select: none;
  cursor:grabbing;
}