.completa{
    padding:3rem;
    background-color: white;
    width: 60vw;
}

.fila{
  margin-top: 0rem;
  margin-bottom: 1rem;
}

textarea, textarea:active, textarea:focus{
    resize:none;
    border-radius: 0px !important;
    outline:0px !important;
    -webkit-appearance:none !important;
    box-shadow: none !important;    
}