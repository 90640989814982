/*FONTS*/
@font-face {
  font-family: 'libre-baskerville-italic';
  src: url('./assets/fonts/LibreBaskerville/LibreBaskerville-Italic.otf');
}
@font-face {
  font-family: 'rubik-medium';
  src: url('./assets/fonts/Rubik/Rubik-Medium.ttf');
}

@font-face {
  font-family: 'rubik-regular';
  src: url('./assets/fonts/Rubik/Rubik-Regular.ttf');
}

.App {
  text-align: center;
  background-color: #e6e9fb;
  position: absolute;
  left: 0;
  width: 100vw;
  height: 100vh;
}


@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}


@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.barra{
  background-color: white;
  width: 90%;
  height:10vh;
  margin:auto;
  text-align: center;
  min-height: 70px;
  font-weight: bold;
}



.contenedor {
  display: grid;

  grid-template-areas:
    "header header header"
    "content content content"
    "footer footer footer";

  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: auto 1fr auto;
  grid-gap: 0px;
  /*background-color: #e6e9fb;  */
  background-color: #f6f7fd;
  min-height: 100vh;
}

.homecontainer{
  background-color: #f6f7fd;
}

/*header {*/
/*  grid-area: header;  */
/*}*/


/*main {*/
/*  grid-area: content;  */
/*}*/

nav.navbar.navbar-expand-lg.navbar-light.bg-light {
  margin-left: 65px;
  margin-right: 65px;
}



footer {
  position: absolute;
  left: 0;
  width: 100%;
  background-color: white;
  color: #a09b9b;
}

.activo{
  border-bottom: 3px solid #adb8f3;
  /*transition: border-bottom .5s ease-in-out;*/
}

body{
  background-color: #f6f7fd;
}

.bg-light {
  background-color: #ffffff!important;
}

span a{
  color: #a09b9b;
}


span a:hover {
  color: #f5b96b !important;
  text-decoration: none;
}

/*footer {*/
/*  grid-area: footer;*/
/*  background-color: white;*/
/*  height: 10vh;*/
/*  min-height: 70px;*/
/*  align-items: center;*/
/*  color:#a09b9b;*/
/*  font-size: 0.9rem;*/
/*}*/

div>span{
  display:flex;
  align-items: center;
  height: 100%;
}

a.link-desconectar{
  color: #adb8f3;
  text-decoration: none;
}

.simpleBoton, .simpleBoton:hover,.simpleBoton:active,.simpleBoton:focus{
  width:100%;
  background-color:#f5b96b;
  border-color:#f5b96b;
  border-radius:0;
  color:white;
}



.link{
  text-decoration: underline;
  color:#ffb65b;
  font-weight: bold;
  font-size: 0.7rem;
}


@media (max-width: 767px) {
  div>span{
    display:flex;
    align-items: center;
    height: 100%;
    text-align: center;
    padding:0px;
  }
}



/* CHANGE CUSTOM FONTS */
.customTitle{
  font-family: 'libre-baskerville-italic';
}