.vl {
    border-left: 1px solid #d1d1d1;
    margin-left: 30px;
    margin-right: 20px;    
  }

  .seccionInformacion{
    background-color: #e6e9fb;    
    justify-content: center;
    align-items: center;
    padding-top: 12px;
    display: flex;
    flex-direction: column;
}
