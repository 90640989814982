.vertical-timeline-element-content{
    box-shadow:0 0px 0 #ddd !important;
}

.vertical-timeline-element-icon{
    box-shadow:0 0px 0 #ddd !important;
    width:30px !important;
    height: 30px !important;
}

.vertical-timeline-element-content-arrow{
    display:none !important;
}

.timeline-right{
    /*padding:0 2em 0 10em;*/
}

.timeline-left{
    padding:0 0 0 2em;        
}

.timeline-left>span:not(:first-child){
    padding-top: .8rem;
}


@media screen and (max-device-width:1000px){
    .timeline-right{
        padding:0;
    }
    .timeline-left{
        padding:0 0 0 1 em;
    }
}