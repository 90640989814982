@font-face {
  font-family: 'Open Sans';
  src: url(./assets/fonts/open_sans/OpenSans.woff) format('woff'),url(./assets/fonts/open_sans/OpenSans-bold.woff) format('woff'),url(./assets/fonts/open_sans/OpenSans-light.woff) format('woff'),url(./assets/fonts/open_sans/OpenSans-semibold.woff) format('woff');
}


body {
  margin: 0;
  padding: 0px;
  
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;  
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
