.seccionLogin{    
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    background-color: #f6f7fd;
    padding-top:15px;    
}


.input{
    border-style:solid;
    border-width:1;
    border-color:#ece9e9;
    margin-bottom: 10px;    
    height:40px;
    padding:10px;
}

.login{
    height:350px;
    min-width: 200px;
    max-width: 350px;
    width:100%;
    background-color:white;
    display:flex;
    flex-direction: column;
    justify-content:center;
    align-items: center;
    border: 1px solid white;
    border-radius: 2px;
    padding-left:2em;
    padding-right:2em;
    /* margin-bottom: 1em; */
}

.login-container{
    justify-content:center;
    align-items: center;
    display:flex;
    flex-direction: column;
}

.input::placeholder {
    color: #d1d1d1;
  }

.olvido{
    margin-top: 10px;
    text-decoration: underline;
    color:#ffb65b;
    text-align: center;
}

.olvido:hover{
    color:#ffb65b;
}

.contenido {
    height: calc(100vh - 91px - 72px - 83px - 48px);
    min-height: 400px;
}  
