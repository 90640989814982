td {
    padding:5px;
    border: 1px gray solid;
}

th {
    padding:5px;
    border: 1px white solid;
}

