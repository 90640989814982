

.activo{
  border-bottom: 3px solid #adb8f3;
  /*transition: border-bottom .5s ease-in-out;*/
}
.simpleBoton, .simpleBoton:hover,.simpleBoton:active,.simpleBoton:focus{
  width:100%;
  background-color:#f5b96b;
  border-color:#f5b96b;
  border-radius:0;
  color:white;    
}
.link{
  text-decoration: underline;
  color:#ffb65b;
  font-weight: bold;
  font-size: 0.7rem;
}

/*SOLID BUTTONS*/
.lg-button-solid{
  width:100%;
  background-color:#f5b96b;
  border-color:#f5b96b;
  border-radius:0;
  color:white;
  height: 60px;
}

.md-button-solid{
  width:100%;
  background-color:#f5b96b;
  border-color:#f5b96b;
  border-radius:0;
  color:white;
  height: 38px;
}


.lg-button-solid:hover{
  width:100%;
  background-color:#484B59;
  border-color:#484B59;
  border-radius:0;
  color:white;
  height: 60px;
}

.md-button-solid:hover{
  width:100%;
  background-color:#484B59;
  border-color:#484B59;
  border-radius:0;
  color:white;
  height: 38px;
}

/*OUTLINED BUTTONS*/

.lg-button-outlined{
  width:100%;
  background-color:transparent;
  border: 2px solid #ACB8F8;
  border-radius:0;
  color:#9AA4D9;
  height: 60px;
}
.md-button-outlined{
  width:100%;
  background-color:transparent;
  border: 2px solid #ACB8F8;
  border-radius:0;
  color:#9AA4D9;
  height: 38px;
}

.lg-button-outlined:hover{
  width:100%;
  background-color:transparent;
  border: 2px solid #484B59;
  border-radius:0;
  color:#484B59;
  height: 60px;
}
.md-button-outlined:hover{
  width:100%;
  background-color:transparent;
  border: 2px solid #484B59;
  border-radius:0;
  color:#484B59;
  height: 38px;
}

/*WARNING BUTTONS*/

.lg-button-warning{
  width:100%;
  background-color:transparent;
  border: 2px solid #D1574A;
  border-radius:0;
  color:#D1574A;
  height: 60px;
}
.md-button-warning{
  width:100%;
  background-color:transparent;
  border: 2px solid #D1574A;
  border-radius:0;
  color:#D1574A;
  height: 35px;
}

.lg-button-warning:hover{
  width:100%;
  background-color:transparent;
  border: 2px solid #484B59;
  border-radius:0;
  color:#484B59;
  height: 60px;
}
.md-button-warning:hover{
  width:100%;
  background-color:transparent;
  border: 2px solid #484B59;
  border-radius:0;
  color:#484B59;
  height: 38px;

}

/*LINKS SOLID*/
.link-solid{
  color:#FFB65B;
}

.link-solid:hover{
  color:#484B59;
}

/*LINKS OUTLINED*/
.link-default{
  color:black;
  text-decoration: underline;
}

.link-outlined{
  color:#9AA4D9;
}

.link-outlined:hover{
  color:#484B59;
}


/*LABELS*/
.label-solid{
  color:#FFB65B;
}

.label-outlined{
  color:#9AA4D9;
}

/*CUSTOM INPUTS*/
.customInput{
  height: calc(1.5em + 1.75rem + 2px) !important;
  border-radius: 0px !important;
}
/*DIVIDER CARDS*/
.divider {
  height: 1px;
  width:100%;
  display:block; /* for use on default inline elements like span */
  margin: 9px 0;
  overflow: hidden;
  background-color: #e5e5e5;
}

.center-button{
  display:flex;
  justify-content: center;
  margin:auto;
}



.MuiStepLabel-label.MuiStepLabel-active {
  color: rgba(0, 0, 0, 0.87);
  font-weight: 500;
  padding-left: 73px;
  font-size: 25px !important;
}


.MuiStepper-root {
  display: flex;
  padding: 60px !important;
}


.MuiStepContent-root {
  border-left: 2px solid #adb8f3 !important;
  
}

.MuiStepConnector-lineVertical {
  min-height: 35px !important;
  border-left-style: solid;
  border-left-width: 2px !important;
}

.MuiStepConnector-line {
  display: block;
  border-color: #adb8f3 !important;
}

.MuiPaper-root {
  color: rgba(0, 0, 0, 0.87);
  transition: box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  background-color: #a93b3b !important;
}




ul.timeline {
  list-style-type: none;
  position: relative;
}
ul.timeline:before {
  content: ' ';
  background: #d4d9df;
  display: inline-block;
  position: absolute;
  left: 29px;
  width: 2px;
  /* height: 100%; */
  height: calc(1em + 142px);
  z-index: 400;
  list-style-image: url('../../assets/img/chispas.png');
}
ul.timeline > li {
  margin: 20px 0;
  padding-left: 70px;
  /* list-style-image: url('../../assets/img/chispas.png');
  list-style-position: inside; */
  
}
/* ul.timeline > li:before {
  content: url('../../assets/img/chispas.png');
  background:#adb8f3;
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  border: 3px solid #adb8f3;
  left: 20px;
  width: 20px;
  height: 20px;
  z-index: 4000;
  
} */

ul.timeline > li:before {
  content: url('../../assets/img/chispas-3.png');
  /* background: #adb8f3; */
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  /* border: 3px solid #adb8f3; */
  left: -31px;
  width: 20px;
  top: -40px;
  height: 20px;
  z-index: 4000;
}

ul.step-activo > li:before {
  content: url('../../assets/img/chispas-2.png');
  /* background: #adb8f3; */
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  /* border: 3px solid #adb8f3; */
  left: -28px;
  width: 20px;
  top: -40px;
  height: 20px;
  z-index: 4000;
}

ul.step-completo > li:before {
  content: ' ';
  /* background: #adb8f3; */
  display: inline-block;
  position: absolute;
  border-radius: 50%;
  /* border: 3px solid #adb8f3; */
  left: -28px;
  width: 20px;
  top: -40px;
  height: 20px;
  z-index: 4000;
}

.hide{
  list-style: none;
  margin-left: 70px;
}

.custom-card{
  border: 0px !important;
  border-radius: 0px !important;
  background: #fff6 !important;
}

.custom-card-active{
  border: 0px !important;
  border-radius: 0px !important;
  
}

.custom-col{
  background: white;
  
}

.custom-card:before {
  content: ' ';
  display: inline-block;
  position: absolute;
  background: #adb8f3;
  left: 59px;
  width: 2px;
  height: 100%;
  z-index: 400;
}

.custom-card-completo:before {
  content: ' ';
  display: inline-block;
  position: absolute;
  background: #adb8f3;
  left: 59px;
  width: 2px;
  height: 0%;
  z-index: 400;
}

/* .custom-card:before {
  content: ' ';
  display: inline-block;
  position: absolute;
  background: #adb8f3;
  left: 59px;
  width: 2px;
  height: 100%;
  z-index: 400;
} */

.custom-card:after {
  content: ' ';
  display: inline-block;
  position: absolute;
  background: #adb8f3;
  left: 59px;
  width: 2px;
  height: 70%;
  z-index: 400;
  margin-top: 60px;
}


.custom-card-active:before {
  content: ' ';
  display: inline-block;
  position: absolute;
  left: 59px;
  width: 2px;
  height: 30%;
  z-index: 400;
}

.custom-card-active:after {
  content: ' ';
  display: inline-block;
  background: #adb8f3;
  position: absolute;
  left: 59px;
  width: 2px;
  height: 75%;
  z-index: 400;
  margin-top: 60px;
}

img.ico {
  position: absolute;
  bottom: 42px;
  left: -25px;
  width: 110px;
}

.customTitle{
  font-family: 'libre-baskerville-italic';
}


h1{
  font-family: 'libre-baskerville-italic';
  font-size: 48px;
}

h2{
  font-family: 'libre-baskerville-italic';
  font-size: 40px;
}


h3{
  font-family: 'rubik-regular';
  font-size: 24px;
}

h4{
  font-family: 'rubik-regular';
  font-size: 18px;
}