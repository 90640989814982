.anadirHitos{
    padding:3rem;
    background-color: white;
    width: 90vw;
}

.fila{
  margin-top: 0rem;
  margin-bottom: 1rem;
}

textarea, textarea:active, textarea:focus{
    resize:none;
    border-radius: 0px !important;
    outline:0px !important;
    -webkit-appearance:none !important;
    box-shadow: none !important;    
}

.botonBorde{
  border-color:#adb8f3;
  padding:0.625rem 1.875rem 0.625rem 1.875rem;  
  font-size: 1rem;
  line-height: 1.5;
  color:#adb8f3;
  font-weight: bold;
  box-shadow: 0;
  background-color: transparent;
  border-style: solid;
  font-size: 1rem;  
  display:inline-block;
  vertical-align: middle;
  margin-left: 0.625em;
}

.closeX{
  font-size: 3rem;
  color:#f0f0f1;
  cursor: pointer;
}

@media screen and (max-device-width:500px){
  .anadirHitos{
    padding:0px;
  }
  .botonBorde{
    margin-left:0;
  }
  
}
